import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/layouts/MdxLayout.js";
import SEO from "../../components/Seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Manage your membership – Archibald James Membership" description="Manage your Archibald James membership" mdxType="SEO" />
    <article className="prose mx-auto">
      <h1 {...{
        "id": "manage-your-membership"
      }}>{`Manage your membership`}</h1>
      <p>{`Login to your account `}<a parentName="p" {...{
          "href": "https://vinoshipper.com/login"
        }}>{`here`}</a>{`.`}</p>
      <p><a parentName="p" {...{
          "href": "/contact"
        }}>{`Contact`}</a>{` us directly with any questions. We're happy to help!`}</p>
    </article>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      